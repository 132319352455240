import React, { ReactElement, ReactNode, useEffect , useState} from 'react';
import Box from '../box/Box';
import Typography from '../typography/Typography';
import theme from '../../theme/Theme';
import Button from '../button/Button';
import classes from './Splash.module.scss';
import Spacing from '../../styles/spacing/Spacing';
import useCustomMediaQuery from '../../customHook';

interface SplashProps {
    title?: string | ReactNode;
    text?: string | ReactNode;
    textAlignment?: 'center' | 'left' | 'right' | 'justified';
    buttonText?: string;
    buttonAction?: () => void;
    buttonId?: string;
    image?: ReactElement | string | any;
    imageAlt?: string;
    buttonVariant?: string;
    children?: any;
    type?: string;
    customSpacing?: string;
    buttonFullWidth?: boolean;
    video?: string;
    isNotBottomCrisp?: boolean;
}

const Splash: React.FC<SplashProps> = ({
    title,
    text,
    textAlignment = 'center',
    buttonText,
    buttonVariant,
    buttonAction,
    buttonId,
    image,
    imageAlt,
    children,
    type,
    customSpacing,
    buttonFullWidth = true,
    video,
    isNotBottomCrisp = false
}) => {

    const isMobile = useCustomMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
    const [player, setPlayer] = useState();
    useEffect(() => {
        if (typeof document !== undefined && !isNotBottomCrisp) {
            const styles = `
            /* Fix crisp chat position if bottom bar is present */
            .crisp-client .cc-1brb6[data-full-view=true] .cc-1yy0g .cc-1m2mf  {
                bottom: 88px !important;
                right: 16px!important;
            }
            .crisp-client .cc-tlyw .cc-kxkl .cc-nsge {
                bottom: 88px !important;
                right: 16px!important;
            }
        `;

            const styleTag = document.createElement('style');
            styleTag.appendChild(document.createTextNode(styles));
            document.head.appendChild(styleTag);

            // Clean up the injected styles when the bottom bar is unmounted
            return () => {
                document.head.removeChild(styleTag);
            };
        }
    }, [isNotBottomCrisp]);
    return (
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
            {image && (
                <Box display='flex' justifyContent='center' mb={4} className={classes['rb-splash-image-container']}>
                    {typeof image === 'string' && <img src={image} alt={imageAlt} />}
                    {typeof image === 'object' && image}
                </Box>
            )}
            {title && (
                <Box mb={4}>
                    <Typography
                        id='title'
                        variant='h2'
                        color={theme.palette.primary.navy}
                        weight='semibold'
                        align='center'>
                        {title}
                    </Typography>
                </Box>
            )}
            {text && (
                <Typography
                    variant='body1'
                    align={textAlignment}
                    style={{ lineHeight: '25.6px' }}>
                    {typeof text === 'string' ?
                        <div dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br /><br />') }}></div>
                        : text}
                </Typography>
            )}
            {video && (
                <Box display='flex' justifyContent='center' mb={4} className={classes['rb-splash-video-container']}>
                    {typeof video === 'string' && <video width={500} height={280} src={video} preload="auto" className={ classes.video }  controls/>}
                </Box>
            )}
            {children ? children : null}
            {buttonText && buttonAction && (
                <>
                {!isMobile && <Spacing variant='contentToCta' />}
                    <Spacing variant={customSpacing ? customSpacing : type === 'success' ?'containerButtonOnBottomSmall2':type === 'XS' ? 'containerButtonOnBottomExtraSmall':'containerButtonOnBottomSmall'} />
                    <Button
                        variant={buttonVariant}
                        fullWidth={buttonFullWidth}
                        id={buttonId}
                        onClick={buttonAction}>
                        {buttonText}
                    </Button>
                </>
            )}
        </Box>
    );
};

export default Splash;
