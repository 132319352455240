import React from 'react'

const Gift = ({ height = 20, width = 20, color = "#1160F7" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0003 5.83329H6.25033C5.69779 5.83329 5.16789 5.6138 4.77719 5.2231C4.38649 4.8324 4.16699 4.30249 4.16699 3.74996C4.16699 3.19743 4.38649 2.66752 4.77719 2.27682C5.16789 1.88612 5.69779 1.66663 6.25033 1.66663C9.16699 1.66663 10.0003 5.83329 10.0003 5.83329ZM10.0003 5.83329H13.7503C14.3029 5.83329 14.8328 5.6138 15.2235 5.2231C15.6142 4.8324 15.8337 4.30249 15.8337 3.74996C15.8337 3.19743 15.6142 2.66752 15.2235 2.27682C14.8328 1.88612 14.3029 1.66663 13.7503 1.66663C10.8337 1.66663 10.0003 5.83329 10.0003 5.83329ZM10.0003 5.83329L4.33366 5.83329C3.40024 5.83329 2.93353 5.83329 2.57701 6.01495C2.2634 6.17474 2.00844 6.42971 1.84865 6.74331C1.66699 7.09983 1.66699 7.56654 1.66699 8.49996L1.66699 15.6666C1.66699 16.6 1.66699 17.0668 1.84865 17.4233C2.00844 17.7369 2.2634 17.9918 2.57701 18.1516C2.93353 18.3333 3.40024 18.3333 4.33366 18.3333L15.667 18.3333C16.6004 18.3333 17.0671 18.3333 17.4236 18.1516C17.7373 17.9919 17.9922 17.7369 18.152 17.4233C18.3337 17.0668 18.3337 16.6 18.3337 15.6666V8.49996C18.3337 7.56654 18.3337 7.09983 18.152 6.74331C17.9922 6.42971 17.7373 6.17474 17.4236 6.01495C17.0671 5.83329 16.6004 5.8333 15.667 5.83329L10.0003 5.83329ZM10.0003 5.83329C10.2503 6.63886 9.50037 9.25 6.75037 10M10.0003 5.83329C9.75031 6.63886 10.5003 9.25 13.2503 10M1.66699 14.1666H18.3337" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default Gift