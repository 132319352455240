import React from 'react'
import classes from './CurrencyConverterBox.module.scss'
import CurrencyInputField from '../moneyTransferBox/CurrencyInputField'
import TextArrowRight from '../../icons/TextArrowRight'
import Typography from '../typography/Typography'
import Button from '../button/Button'
import DottedLines from '../../icons/DottedLines'
import theme from '../../theme/Theme'
import ExchangeIcon from '../../icons/ExchangeIcon'
import Box from '../box/Box'
import { MathOperation } from '../../../shared/math'
import { useMediaQuery } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

type CEBox = {
    /**
     * `string` - unique character
     */
    id: string;
    /**
     * List of countries
     */
    countriesList: any[];
    /**
     * Primary currency object holds (iso2, currency_code, currency_name)
     */
    primaryCurrency: any;
    /**
     * Secondary currency object holds (iso2, currency_code, currency_name)
     */
    secondaryCurrency: any;
    /**
     * Primary currency change handler
     */
    primaryCurrencyChangeHandler: Function;
    /**
     * Secondary currency change handler
     */
    secondaryCurrencyChangeHandler: Function;
    /**
     * Sending currency value (each dollar rate)
     */
    sendingCurrencyValue: string;
    /**
     * Receiving currency value (each dollar rate)
     */
    receivingCurrencyValue: string;
    /**
     * Sending currency amount (user input)
     */
    sendingCurrencyAmount?: string | number;
    /**
     * Receiving currency amount (user input)
     */
    receivingCurrencyAmount?: string | number;
    /**
     * Swap currencies handler
     */
    onSwapHandler: () => void;
    /**
     * Special rate in string for primary currency
     */
    primarySpecialRateText?: string;
    /**
     * Special rate in string for secondary currency
     */
    secondarySpecialRateText?: string;
    /**
     * `boolean` - to display loading state
     */
    sendAmountLoading?: boolean;
    /**
     * `boolean` - to display loading state
     */
    receiveAmountLoading?: boolean;
    /**
     * `function` - to handle send currency change. returns input string
     */
    onSendCurrencyAmountChange: Function;
    /**
     * `function` - to handle receive currency change. returns input string
     */
    onReceiveCurrencyAmountChange: Function;
    /**
     * `string` - to indicate user input value
     */
    sendAmount: string;
    /**
     * `string` - to indicate user input value
     */
    receiveAmount: string;
    sendFieldErrorMessage?: string;
    receiveFieldErrorMessage?: string;
    autoFocus?: boolean;
    currencyRateLoading?: boolean;
}

const CurrencyExchangeBox:React.FC<CEBox> = ({
   id,
   countriesList,
   primaryCurrency,
   secondaryCurrency,
   primaryCurrencyChangeHandler,
   secondaryCurrencyChangeHandler,
   sendingCurrencyValue,
   receivingCurrencyValue,
   onSwapHandler,
   primarySpecialRateText,
   secondarySpecialRateText,
   sendAmountLoading,
   receiveAmountLoading,
   onSendCurrencyAmountChange,
   onReceiveCurrencyAmountChange,
   sendAmount,
   receiveAmount,
   autoFocus = true,
   sendFieldErrorMessage,
   receiveFieldErrorMessage,
   currencyRateLoading
}) => {
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`)
    return (
        <div id={id} className={classes[`rb-currencyExchange-container`]}>
            <CurrencyInputField
                label="You have"
                id="sendingEnd"
                countrySelectable={true}
                flagCode={primaryCurrency?.iso2}
                currency={primaryCurrency?.currency_code}
                loading={sendAmountLoading}
                editable={!receiveAmountLoading}
                handleCountryChange={primaryCurrencyChangeHandler}
                handleChange={onSendCurrencyAmountChange}
                countriesList={countriesList}
                value={sendAmount}
                autoFocus={autoFocus}
                errorMessage={sendFieldErrorMessage}
            />

            <div className={classes[`rb-exchangeRate-info`]}>
                <div className={classes[`rb-dotted-line`]}>
                </div>
                <div className={classes[`rb-rate`]}>
                    <div className={classes[`rb-circle`]}>
                        <Button onClick={onSwapHandler} id="swap-currency" variant='outlined' style={{ borderRadius: '50%', padding: '8px', height: '36px' }}><ExchangeIcon color={theme.palette.primary.blue} /></Button>
                    </div>
                    <div className={classes[`rb-rateAmount`]}>
                        <div className={classes[`rb-convertedRate`]}>
                            <Box display='flex' style={{ gap: '8px'}}>
                                {currencyRateLoading ? <Skeleton width={120} height={40} />:primaryCurrency && receivingCurrencyValue && <Typography id='amount' weight='semibold' variant={isMobile ? "body1" : 'h5'} color={theme.palette.primary.navy}>{` 1 ${primaryCurrency?.currency_code} =`} <span style={{ textDecoration: primarySpecialRateText ? 'line-through' : 'inherit' }}>{` ${receivingCurrencyValue} ${!primarySpecialRateText ? secondaryCurrency?.currency_code : ''}`}</span></Typography>}
                                {primarySpecialRateText && <Typography id="specialRate" weight='semibold' variant={isMobile ? "body1" : 'h5'} color={theme.palette.success.dark}>{` ${primarySpecialRateText} ${secondaryCurrency?.currency_code}`}</Typography>}
                            </Box>
                            <Box display='flex' style={{ gap: '8px'}}>
                                {currencyRateLoading ? <Skeleton width={120} height={40} />:secondaryCurrency && sendingCurrencyValue && <Typography id='amount' weight='regular' variant={isMobile ? "label2" : 'label1'} color={theme.palette.input.body}>{` 1 ${secondaryCurrency?.currency_code} =`}<span style={{ textDecoration: secondarySpecialRateText ? 'line-through' : 'inherit' }}>{` ${sendingCurrencyValue} ${!secondarySpecialRateText? primaryCurrency?.currency_code : ''}`}</span></Typography>}
                                {secondarySpecialRateText && <Typography id="specialRate" weight='regular' variant={isMobile ? "label2" : 'label1'} color={theme.palette.success.dark}>{` ${secondarySpecialRateText} ${primaryCurrency?.currency_code}`}</Typography>}
                            </Box>
                        </div> 
                    </div>
                </div>
            </div>


            <CurrencyInputField
                label="You receive"
                id="receiveEnd"
                countrySelectable={true}
                flagCode={secondaryCurrency?.iso2}
                currency={secondaryCurrency?.currency_code}
                loading={receiveAmountLoading}
                editable={!sendAmountLoading}
                handleCountryChange={secondaryCurrencyChangeHandler}
                handleChange={onReceiveCurrencyAmountChange}
                countriesList={countriesList}
                value={receiveAmount}
                errorMessage={receiveFieldErrorMessage}
            />

        </div>
    )
}

export default CurrencyExchangeBox