import React, { useEffect, useRef, useState } from 'react';
import Box from '../../../../../remitbee/components/box/Box';
import classes from './TabsSection.module.scss';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../../../remitbee/theme/Theme';
import Typography from '../../../../../remitbee/components/typography/Typography';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';
import Button from '../../../../../remitbee/components/button/Button';
import { setRedirectSignupCookie } from '../../../../../shared/cookie-handler';
import { useRouter } from 'next/router';
import { getImageAlt, parseArrFromString } from '../../../../../shared/utility';
import Markdown from 'markdown-to-jsx';
import { getCountriesPublicByType , esimProductsPublic} from '../../../../../redux/esim/actions'
import BoxList from '../../BoxList';
import ProductCards from '../../../../eSim/ProductSelection/ProductCards';
import { useTranslation } from 'next-i18next';
import Input from '../../../../../remitbee/components/input/Input';
import SearchIcon from '../../../../../remitbee/icons/SearchIcon';

interface Props {
    reverse?: boolean;
    reverseMobile?: boolean;
    backgroundColor?: string;
    promotion?: any;
    listItems: any;
    title?: string;
    align?: string;
    titleSize?: string;
    type?: string;
}

const TabsSectionDS:React.FC<Props> = ({
    reverse,
    reverseMobile,
    backgroundColor,
    promotion,
    listItems,
    title,
    align,
    titleSize,
    type,
}) => {
    const { t } = useTranslation('e-sim');
    const isNotTablet = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const router = useRouter();
    const [countriesListData, setCountriesListData] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [globalList, setGlobalList] = useState(null);
    const [globalListData, setGlobalListData] = useState(null);
    const [localList, setLocalList] = useState(null);
    const [regionalList, setRegionalList] = useState(null);

    const scrollRef = useRef(null);

    useEffect(() => {
        const fetchProducts = async () => {
    
            try {
              const promises = [];
              promises[0] = getCountriesPublicByType('LOCAL');
              promises[1] = getCountriesPublicByType('REGIONAL');
              promises[2] = getCountriesPublicByType('GLOBAL');
        
              const [resultLocal, resultRegional, resultGlobal] = await Promise.all(promises);
              const countriesList = {
                local: resultLocal?.data?.getCountriesPublic?.countries,
                regional: resultRegional?.data?.getCountriesPublic?.countries,
                global: resultGlobal?.data?.getCountriesPublic?.countries
              }
              setCountriesListData(countriesList);
              setLocalList(countriesList?.local);
              setRegionalList(countriesList?.regional);
              const { success, data, errorMessage } = await esimProductsPublic(
                countriesList?.global[0].countryId,
                null,
                4,
                'FIXED_VALUE_PIN_PURCHASE'
            );
            if(success && data && data.getProductsPublic) {
                data.getProductsPublic?.products && setGlobalList(data.getProductsPublic?.products);
            }
            } catch (error) {
            //   snackbar.error('Something went wrong', 3000)
            } finally {
            //   setLoading(false)
            }
          }
          fetchProducts();

    },[])

    useEffect(() => {
        if(countriesListData) {
            setLocalList(countriesListData?.local?.filter(country => country?.name.toLowerCase().includes(searchText.toLowerCase())));
            setRegionalList(countriesListData?.regional?.filter(country => country?.name.toLowerCase().includes(searchText.toLowerCase())));
        }
    },[searchText])

    useEffect(() => {
        if(globalList) {
            const data = [...globalList].sort((a, b) => {return parseFloat(a.retailAmount) - parseFloat(b.retailAmount)})
            setGlobalListData(data)
        }
    }, [globalList])

    let  listEsimData=[
        {
            "tabHeader": "Local",
            "title":"Explore Local plans",
            "text":"Get a prepaid eSIM data plan at local rates in 140+ countries. Enjoy the convenience of reliable and high-speed mobile data tailored to meet your needs.",
            "body": <BoxList withoutTopPadding={true} list={localList} title={''} withOutImage={true} />
          },
          {
            "tabHeader": "Regional",
            "title":"Explore Regional planss",
            "text":"Stay connected across multiple countries with our Regional prepaid eSIM plan. Access fast and reliable data and seamless connectivity without borders.",
            "body": <BoxList withoutTopPadding={true} list={regionalList} title={''} withOutImage={true} isBox={true}/>
          },
          {
            "tabHeader": "Global",
            "title":"Explore Global plans",
            "text":"Explore the world with our Global eSIM plan, offering uninterrupted mobile data across the globe. Experience high-speed connectivity no matter where you travel.",
            "body":<>
            <Box gap={16}  flexWrap='wrap' display='flex'>{globalListData?.map((data) => {return  <ProductCards plan={data}  selectedPlan={null} destination_type={'public'} t={t} onSelect={()=>{}}/>})}
            </Box>
            <Spacing variant='betweenSections' />
            <Box display='flex' justifyContent='center' alignItems='center'><Button variant='outlined' id='Know more about Global plans'>{'Know more about Global plans'}</Button></Box>
            </>
          }
    ];

    

    const list = listItems ? typeof listItems==='string' ? parseArrFromString(listItems) : listItems : type==='esim' ? listEsimData : null;

    const setSelectedTabAndScroll = (index) => {
        setSelectedTab(index);
        if (scrollRef.current) {
            const containerWidth = scrollRef?.current?.clientWidth;
            const tabWidth = scrollRef?.current?.children[index]?.offsetWidth;
            const tabPosition = scrollRef?.current?.children[index]?.offsetLeft;
            const scrollPosition = tabPosition - (containerWidth / 2 - tabWidth / 2);
            scrollRef?.current?.scrollTo({
                left: scrollPosition,
                behavior: 'smooth'
            });
        }
    };

    const addReferralLink = () => {
        let _args = {}
        _args = { promo: promotion };
        return _args;
    }

    const goToUrl = async (actionUrl) => {
        if (!actionUrl) return;
        if (actionUrl.startsWith('http')) {
            window.open(actionUrl, '_blank');
        } else if (actionUrl.startsWith('www')) {
            window.open(`https://${actionUrl}`, '_blank');
        } else {
            let query = null;
            if (actionUrl && actionUrl.includes('signup')) await setRedirectSignupCookie(router.asPath)
            if (actionUrl && actionUrl.includes('signup?')) {
                router.push(actionUrl);
                return
            }
            if (promotion) query = addReferralLink()
            router.push({ pathname: actionUrl, query });
        }
    };

    return (
        <section style={{ backgroundColor: backgroundColor || null}}>
        <Box className={classes['rb-root']}>
        <Box
            display='flex' 
            flexDirection={!isNotTablet ? reverseMobile ? 'column-reverse' : 'column' : reverse ? 'row-reverse' : 'row'}
            justifyContent='center'
            alignItems={title ? 'center' :'flex-start'}
            gap={!isNotTablet ? 40 : 64}
        >
            
            {list?.length > 0 && list[selectedTab] && list[selectedTab]?.imageUrl && <Box display='flex' justifyContent={!isNotTablet ? 'center' : reverse ? 'flex-start' : 'flex-end'} alignItems='center' className={classes['rb-image-box']}>
                 <img src={list[selectedTab]?.imageUrl} alt={getImageAlt(list[selectedTab]?.imageUrl)} className={classes['rb-image']} />
            </Box>}
            <Box>
           {
            title && <>
            <div className={isMobile ? classes['rb-title'] : null}>
           <Typography variant={type === 'esim' ? 'displayXl' :'displayLg'} weight='bold' color={theme.palette.primary.navy} align={align && align}>
                    {title}
                </Typography>
                <Spacing variant='betweenSections' />
           </div>
            </>
           }
           {
            type === 'esim' && <Box display='flex' alignItems='center' justifyContent='center'>
                <Box mt={6} mb={8} className={classes['rb-search']}>
            <Input
              id='search-destination'
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={'Search for a country or a region'}
              startAdornment={<SearchIcon width={20} height={20} color={theme.palette.input.body} />}
              fullWidth
            />
          </Box>
                </Box>
           }
            <Box componentRef={scrollRef} className={classes['rb-tab-title-box']} justifyContent={align && align}>
                {list?.map((item, index) => { return (
                    <Box 
                    className={`
                        ${classes['rb-tab-title']}
                        ${index === selectedTab && classes['rb-tab-title-active']}
                    `}
                    onClick={()=>setSelectedTabAndScroll(index)}
                    >
                        <Typography variant='textMd' weight='semibold' className={classes['rb-tab']} color={index === selectedTab && theme.palette.background.white}>
                            <Markdown children={item.tabHeader as React.ReactNode} />
                        </Typography>
                    </Box>)
                })}
            </Box>
            {list?.length > 0 && list[selectedTab] && 
            <Box className={classes['rb-content-box']} >
                <Spacing variant='betweenSections' />
                <Box display='flex' justifyContent='flex-start' flexDirection='column' gap={16}>
                    <Box display='flex' flexDirection='column' gap={8}>
                       {list[selectedTab]?.tag && <Typography variant='textMd' weight='bold' color={theme.palette.primary.blue} align={align && align}>
                            <Markdown children={list[selectedTab]?.tag} />
                        </Typography>}
                        <Typography variant={titleSize? titleSize :'displayLg'} weight='bold' color={theme.palette.primary.navy} align={align && align}>
                            <Markdown children={list[selectedTab]?.title} />
                        </Typography>
                    </Box>
                    <Typography paragraph={true} variant='textMd' style={{ display: 'inline'}} align={align && align}>
                        <Markdown children={list[selectedTab]?.text} style={{ display: 'inline'}} />
                    </Typography>
                </Box>
                <Spacing variant='betweenSections' />
               {list[selectedTab]?.actionText && <Box display='flex' justifyContent='flex-start' flexDirection='column' gap={16}>
                    <Button id={`Tab-${selectedTab}`} fullWidth={!isNotTablet} variant={list[selectedTab]?.actionVariant || 'outlined'} onClick={()=>goToUrl(list[selectedTab]?.actionUrl)} className={classes['rb-button']}>
                            {list[selectedTab]?.actionText}
                    </Button>
                </Box>}
                {
                   list[selectedTab]?.body && list[selectedTab]?.body 
                }
            </Box>}
            </Box>
        </Box>
        <Spacing variant='fromHeaderToContentInSection' />
        </Box>
        </section>
    );
};

export default TabsSectionDS;